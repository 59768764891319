<template>
  <IchibanTemplate>
    <template v-slot:body>
      <div v-if="user" class="wrapper">
        <h4 class="text-center category-heading">
          <v-icon color="white">mdi-alert-decagram</v-icon>
          {{ branch }}店
        </h4>
        <div class="tool mb-4">
          <div>
            <v-text-field
              append-icon="mdi-magnify"
              label="關鍵字"
              v-model.trim="keyword"
              outlined
              dense
              hide-details
              clearable
              solo
              dark
            >
            </v-text-field>
          </div>
          <div class="switcher">
            <div
              class="switcher__item switcher__item--left"
              :style="{ background: mode === 'grid' ? 'cyan' : '#1e1e1e' }"
            >
              <v-icon
                :color="mode === 'grid' ? 'black' : 'white'"
                @click="mode = 'grid'"
                >mdi-view-grid</v-icon
              >
            </div>
            <div
              class="switcher__item switcher__item--right"
              :style="{ background: mode === 'row' ? 'cyan' : '#1e1e1e' }"
            >
              <v-icon
                :color="mode === 'row' ? 'black' : 'white'"
                @click="mode = 'row'"
              >
                mdi-land-rows-horizontal
              </v-icon>
            </div>
          </div>
        </div>
        <div v-if="!!ichibans && !filterIchibans.length" class="text-center">
          <h4>這裡還沒有一番賞～</h4>
        </div>
        <div
          :class="{
            'item-wrapper': true,
            grid: mode === 'grid',
            row: mode === 'row',
          }"
        >
          <template v-if="mode === 'row'">
            <IchibanRowCard
              :elevation="4"
              v-for="(ichiban, n) of filterIchibans"
              :key="ichiban._id"
              @click="selectIchiban(ichiban)"
              :ichiban="ichiban"
            />
          </template>
          <template v-else>
            <IchibanGridCard
              :elevation="4"
              v-for="(ichiban, n) of filterIchibans"
              :key="ichiban._id"
              @click="selectIchiban(ichiban)"
              :ichiban="ichiban"
            />
          </template>
        </div>
      </div>
    </template>
  </IchibanTemplate>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import liff from "@line/liff";
import util from "@/mixins/util";
import { mapState } from "vuex";
import IchibanTemplate from "./IchibanTemplate.vue";
import IchibanGridCard from "@/components/client/IchibanGridCard.vue";
import IchibanRowCard from "@/components/client/IchibanRowCard.vue";

export default {
  name: "Ichiban",
  mixins: [util],
  components: {
    IchibanTemplate,
    IchibanGridCard,
    IchibanRowCard,
  },
  data: () => ({
    dialog: false,
    ichibans: null,
    keyword: "",
    mode: "row",
  }),
  async created() {
    if (!this.userID) {
      return this.$router.push({
        name: "Ichiban",
        query: { store: "@695yimoc" },
      });
    }

    await this.getCommonImage();
    this.load();
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    this.load();
  },
  computed: {
    ...mapState("clientModule", {
      userID: (state) => state.userID,
      UID: (state) => state.UID,
      user: (state) => state.user,
      token: (state) => state.token,
      store: (state) => state.store,
      images: (state) => state.images,
    }),
    branch() {
      return this.$route.params.branch;
    },
    filterIchibans() {
      const ichibans = this.ichibans;
      if (!ichibans) return [];

      return ichibans
        .filter((ichiban) => ichiban.isOnline && ichiban.isAvailable)
        .filter((ichiban) => {
          return (
            !this.keyword ||
            ichiban.name.toUpperCase().includes(this.keyword?.toUpperCase())
          );
        })
        .sort((a, b) => {
          const isLockToUserA = this.getIsLockedToUser(a);
          const isLockToUserB = this.getIsLockedToUser(b);
          const isReservedA = this.getIsLockedToOthers(a);
          const isReservedB = this.getIsLockedToOthers(b);
          const isSoldoutA = this.getIsSoldout(a);
          const isSoldoutB = this.getIsSoldout(b);

          if (isLockToUserA !== isLockToUserB) {
            return isLockToUserA ? -1 : 1;
          }
          if (isReservedA !== isReservedB) {
            return isReservedA ? -1 : 1;
          }
          if (isSoldoutA !== isSoldoutB) {
            return isSoldoutA ? 1 : -1;
          }
          return 0;
        });
    },
  },
  methods: {
    async load() {
      this.$vloading.show();
      await this.getIchibans();
      this.$vloading.hide();
    },
    selectIchiban(ichiban) {
      this.$router.push({
        name: "IchibanPage",
        params: { id: ichiban._id },
      });
    },
    async getIchibans() {
      this.ichibans = (
        await this.axios.get("/ichiban", {
          params: { isOnline: true, isAvailable: true, branch: this.branch },
        })
      ).data;
    },
    async getUser() {
      try {
        this.$vloading.show();
        let payload = { store: this.store, token: this.token };
        let { data } = await this.axios.post(`/getUser`, payload);

        this.$store.commit("clientModule/setState", {
          user: data,
        });
      } catch (error) {
        alert(error);
      } finally {
        this.$vloading.hide();
      }
    },
    closeLiff() {
      liff.closeWindow();
    },
    getIsSoldout(ichiban) {
      return (
        ichiban.count === ichiban.sold ||
        ichiban.rewards.every((reward) => !!reward.hit)
      );
    },
    getIsLockedToOthers(ichiban) {
      if (ichiban.lockTo && !dayjs().isAfter(ichiban.lockTo)) {
        if (ichiban.lockToUser !== this.userID) {
          return true;
        }
      }
    },
    getIsLockedToUser(ichiban) {
      if (ichiban.lockTo && !dayjs().isAfter(ichiban.lockTo)) {
        if (ichiban.lockToUser === this.userID) {
          return true;
        }
      }
    },
    async getCommonImage() {
      const { data } = await this.axios.get("/ichiban/images");
      const imageMap = _.keyBy(data.images, "type");
      this.$store.commit("clientModule/setState", {
        images: {
          background: imageMap.background.url,
          win: imageMap.win.url,
          lose: imageMap.lose.url,
        },
      });
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.wrapper {
  color: white;
}

.banner {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 0 auto;
  padding: 20px 16px;

  @media (min-width: 600px) {
    padding: 30px 24px;
  }

  background-image: url(~@/assets/banner-frame.png);
  background-size: 100% 100%;
}

.tool {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: calc(100% - 24px);
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
}

.switcher {
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;

    &--left {
      border-radius: 5px 0 0 5px;
    }

    &--right {
      border-radius: 0 5px 5px 0;
    }
  }
}

.item-wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0px;

  &.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;

    @media (min-width: 600px) {
      // grid-template-columns: repeat(auto-fit, minmax(150px, 180px));
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
    }
  }

  &.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.category-heading {
  font-size: 24px;
}
</style>
